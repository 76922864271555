// Init Items to store items gotten from database, cart to track and update shopping items
let items, cart, billSummary;
const localCartStorageEvent = new CustomEvent("localStorageCartChange"); // Used to update cart in real time
const billSummaryReceivedEvent = new CustomEvent("billSummaryRecieved");
const urgentCartUploadCompletedEvent = new CustomEvent(
	"urgentCartUploadCompleted"
);

const findItemIndex = (itemId) =>
	cart.findIndex((item) => item.itemId === itemId);

const getUserId = () =>
	document.querySelector(".userIdNoDisplay")?.userIdElement?.dataset.userId;

const uploadLocalCartToRemoteDB = async function () {
	try {
		const userId = getUserId();
		if (!userId) return;

		const localStorageCart = JSON.parse(localStorage.getItem("cart"));
		if (!localStorageCart) return;

		// Formatting local cart data to support remote cart
		const formattedRemoteCartItems = localStorageCart.map((item) => ({
			itemId: item.itemId,
			itemQty: item.itemCartQuantity,
		}));

		const response = await fetch(`/cart/${userId}`, {
			method: "PATCH",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				user: userId,
				items: formattedRemoteCartItems,
			}),
		});

		if (!response.ok) throw new Error("Cart Synchronization failed!");
	} catch (err) {
		console.error(err);
		return 0;
	}
};

// Update remote and local cart after page load
const syncLocalAndRemoteCart = async function () {
	try {
		const userId = getUserId();
		if (!userId) return;

		const res = await fetch(`/cart/${userId}`);
		if (!res.ok) throw new Error("An error occured while fetching cart");

		const remoteCart = (await res.json()).data[0];

		// Upload cart to remote database if cart only exist in local storage
		if (!remoteCart) return await uploadLocalCartToRemoteDB();

		// Replace local storage cart with cart from remote database
		const formattedLocalCart = remoteCart.items.map(({ itemId, itemQty }) => ({
			itemId,
			itemCartQuantity: itemQty,
		}));
		localStorage.setItem("cart", JSON.stringify(formattedLocalCart));
	} catch (err) {
		console.error(err);
		return 0;
	}
};

const updateCartLocalStorage = async function () {
	// Updating cart in local storage
	const cartItems = cart
		.filter((item) => item.itemCartQuantity > 0)
		.map((item) => ({
			itemId: item.itemId,
			itemCartQuantity: item.itemCartQuantity,
		}));
	localStorage.setItem("cart", JSON.stringify(cartItems));

	window.dispatchEvent(localCartStorageEvent);
};

// HANDLING ITEM SELECTION OPERATIONS (NOT CART OPERATIONS)
export const increaseItemCount = function (itemId) {
	cart[findItemIndex(itemId)].selectedItemCount++;
};

export const decreaseItemCount = function (itemId) {
	const item = cart[findItemIndex(itemId)];
	if (
		item.itemCartQuantity + item.selectedItemCount - 1 >= item.minSell &&
		item.selectedItemCount - 1 !== 0
	) {
		item.selectedItemCount--;
		return 1;
	} else {
		return;
	}
};

// HANDLING CART OPERATIONS (UPDATE CART AFTER EACH OPERATION)
export const addItemToCart = async function (itemId) {
	const item = cart[findItemIndex(itemId)];
	if (item.minSell > item.selectedItemCount + item.itemCartQuantity)
		return false;

	item.itemCartQuantity += item.selectedItemCount;
	await updateCartLocalStorage();

	return item;
};

export const increaseCartItemCount = async function (itemId) {
	cart[findItemIndex(itemId)].itemCartQuantity++;
	await updateCartLocalStorage();
};

export const decreaseCartItemCount = async function (itemId) {
	const item = cart[findItemIndex(itemId)];
	if (item.itemCartQuantity - 1 >= item.minSell) {
		item.itemCartQuantity--;
		await updateCartLocalStorage();
		return 1;
	} else {
		return 0;
	}
};

export const deleteCartItem = async function (itemId) {
	const item = cart[findItemIndex(itemId)];
	item.itemCartQuantity = 0;
	item.selectedItemCount = item.minSell;
	await updateCartLocalStorage();
};

// EXPORTED SIMPLE FUNCTIONS
export const getItemInCartCount = () =>
	cart.filter((item) => item.itemCartQuantity > 0).length;

export const getItemInCart = () =>
	cart.filter((item) => item.itemCartQuantity > 0);

export const getItemInCartId = () =>
	cart.filter((item) => item.itemCartQuantity > 0).map((item) => item.itemId);

export const getItemDetails = (itemId) => cart[findItemIndex(itemId)];

export const getShopMenuDetails = () => items;
export const getBillSummary = () => billSummary;

export const updateCartOnChange = async function () {
	try {
		const webSocketURL = `${location.protocol === "https" ? "wss" : "ws"}://${
			location.host
		}`;
		let webSocket = new WebSocket(webSocketURL);

		const webSocketSend = (data) => {
			if (webSocket.readyState !== webSocket.CLOSED)
				return webSocket.send(data);

			webSocket = new WebSocket(webSocketURL);
			const webSocketOpenCheck = window.setInterval(function () {
				if (webSocket.readyState !== webSocket.OPEN) return;
				window.clearInterval(webSocketOpenCheck);
				webSocket.send(data);
			}, 1500);
		};

		webSocket.onopen = () => {
			window.addEventListener("localStorageCartChange", () =>
				webSocketSend(JSON.stringify({ cart, userId: getUserId() }))
			);
			window.addEventListener("urgentCartUpload", () =>
				webSocketSend(JSON.stringify({ urgentCartUpload: true }))
			);
			window.dispatchEvent(localCartStorageEvent);
		};

		webSocket.onmessage = (message) => {
			const parsedMsg = JSON.parse(message.data);
			if (parsedMsg.urgentCartUploaded)
				window.dispatchEvent(urgentCartUploadCompletedEvent);
			else {
				billSummary = parsedMsg.billSummary;
				window.dispatchEvent(billSummaryReceivedEvent);
			}
		};
	} catch (err) {
		console.error(err);
	}
};

export const init = (async function () {
	// Get all items from db and store in items
	const itemsPromise = await fetch("/item", { method: "GET" });
	items = (await itemsPromise.json()).data;

	// Initialize the cart array isomg items
	cart = items.map((item) => ({
		itemName: item.itemName,
		itemId: item._id,
		itemPrice: item.unitPrice,
		itemImg: item.coverImg,
		selectedItemCount: item.minSell,
		itemCartQuantity: 0,
		minSell: +item.minSell,
	}));

	// Sychronize local and remote cart
	await syncLocalAndRemoteCart();

	// Load local cart to cart array if it exists
	if (location.pathname === "/order-successful")
		localStorage.setItem("cart", JSON.stringify([]));

	const localStorageCart = JSON.parse(localStorage.getItem("cart"));
	if (localStorageCart) {
		localStorageCart.forEach((item) => {
			const cartItem = cart[findItemIndex(item.itemId)];
			if (!cartItem) return;
			cartItem.itemCartQuantity = item.itemCartQuantity;
		});
	}

	// Update cart when changes are made
	await updateCartOnChange();
})();

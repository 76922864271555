class View {
  // Selecting Btns
  backToTopBtn = document.querySelector(".backToTopBtn");
  openSignupBtn = document.querySelector(".openSignupBtn");
  openLoginBtn = document.querySelector(".openLoginBtn");
  logoutBtn = document.querySelector(".logoutBtn");
  openCartBtn = document.querySelector(".cartOpenContainer");
  // Selecting Elements
  notifTitleEl = document.querySelector(".notifyTitle");
  notifMsgEl = document.querySelector(".notifyMsg");
  header = document.querySelector("header");
  topNav = document.querySelector("header .topNav");
  topBanner = document.querySelector("header .topBanner");
  cartOverlayContainer = document.querySelector(".cartOverlayContainer");
  loginOverlayContainer = document.querySelector(".loginOverlayContainer");
  signupOverlayContainer = document.querySelector(".signupOverlayContainer");
  loadingAnimationOverlayContainer = document.querySelector(
    ".loadingAnimationContainer"
  );
  // Selecting Group Parents Elements
  allOverlays = document.querySelectorAll(".overlayContainer");
  navContainerEl = document.querySelectorAll(".menuLinkContainer");

  constructor() {
    this.listenAllBtnClicks();

    // Add Intersection observer to nav
    this.addStickyNav();

    // Add loading animation
    this.addLoadingAnimation();
  }

  addStickyNav() {
    const topNavCallback = function (entries) {
      const entry = entries[0];
      if (!entry.isIntersecting) {
        this.topNav.style.opacity = "1";
        // this.topBanner.style.opacity = "1";
        this.topNav.classList.add("stickyTopNav");
        // this.topBanner.classList.add("stickyTopBanner");
      } else {
        this.topNav.classList.remove("stickyTopNav");
        // this.topBanner.classList.remove("stickyTopBanner");
      }
    };
    const topNavOptions = {
      rootMargin: "0px 0px 0px 0px",
      threshold: [0, 1],
    };
    const topNavObserver = new IntersectionObserver(
      topNavCallback.bind(this),
      topNavOptions
    );
    topNavObserver.observe(this.header);
  }

  addLoadingAnimation() {
    const originalFetch = window.fetch;
    const { loadingAnimationOverlayContainer } = this;
    window.fetch = async function (...args) {
      loadingAnimationOverlayContainer.classList.add(
        "showLoadingAnimationContainer"
      );
      try {
        const res = await originalFetch(...args);
        loadingAnimationOverlayContainer.classList.remove(
          "showLoadingAnimationContainer"
        );
        return res;
      } catch (error) {
        loadingAnimationOverlayContainer.classList.remove(
          "showLoadingAnimationContainer"
        );
        throw error;
      }
    };
  }

  controlNavMobileView() {
    this.closeNavMenu.addEventListener("click", () => {
      this.navContainerEl.forEach((navContainer) => {
        navContainer.classList.remove("showNavContainer");
      });
    });
  }

  listenAllBtnClicks() {
    this.backToTopBtn.addEventListener("click", () => scrollTo(0, 0));

    if (this.openSignupBtn)
      this.openSignupBtn.addEventListener(
        "click",
        this.showSignUpOverlay.bind(this)
      );

    if (this.openLoginBtn)
      this.openLoginBtn.addEventListener(
        "click",
        this.showLoginOverlay.bind(this)
      );

    if (this.logoutBtn) {
      this.logoutBtn.addEventListener("click", async () => {
        const res = await fetch("/user/logout", { method: "POST" });

        if (res.ok) location.reload();
        else {
          const result = await res.json();
          this.showNotification("Error", result.message, 3, "error");
        }
      });
    }

    this.openCartBtn.addEventListener("click", this.showCartOverlay.bind(this));

    // Hide all overlays when clicked
    this.allOverlays.forEach((overlay) =>
      overlay.addEventListener("click", (e) => {
        if (
          !["overlayContentContainer", "overlayContainer"].includes(
            e.target.classList[0]
          )
        ) {
          return;
        } else {
          overlay.classList.toggle("showOverlayContainer");
        }
      })
    );
  }

  showNotification(title, msg, time = 2, notifType = "success") {
    // Remove old notification
    const previousNotif = document.querySelector(".showNotif");
    if (previousNotif) {
      previousNotif.remove();
      previousNotif.opacity = 0;
    }

    // Creating Notification Element
    const notifContainer = document.createElement("div");
    notifContainer.classList.add("notifyContainer");
    if (notifType !== "success") notifContainer.style.background = "red";
    notifContainer.innerHTML = `<p class="notifyMsg">${msg}</p>`;
    if ((notifType !== "success" && notifType === "error") || title === "Error")
      notifContainer.style.backgroundColor = "#f34747";

    notifContainer.addEventListener("dblclick", () => {
      notifContainer.classList.remove("showNotif");
    });

    document.body.insertAdjacentElement("beforeend", notifContainer);

    // Trigerring the show animation
    setTimeout(() => notifContainer.classList.add("showNotif"), 0);

    // Removing the notification after some time
    setTimeout(() => {
      notifContainer.classList.remove("showNotif");
      setTimeout(() => notifContainer.remove(), 1000);
    }, time * 1000);
  }

  showUnderConstruction() {
    this.showNotification(
      "",
      `Oops! This feature is in the works. Our team is on it. Thanks for your patience! 🛠️`,
      3.5
    );
  }

  showSignUpOverlay() {
    this.signupOverlayContainer.classList.toggle("showOverlayContainer");
  }
  showLoginOverlay() {
    this.loginOverlayContainer.classList.toggle("showOverlayContainer");
  }
  showCartOverlay() {
    this.cartOverlayContainer.classList.toggle("showOverlayContainer");
  }
}

// Create an instance of the View class to activate the event listeners
export default new View();

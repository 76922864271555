import View from "./views.js";
const urgentCartUploadEvent = new CustomEvent("urgentCartUpload");

class BillView {
  constructor() {
    const containerSelector = ".overlaySectionContainer.billSection";
    const chargesSelector = ".additionalCharges";

    this.billViewContainer = document.querySelector(containerSelector);
    this.billSubtotal = this.getBillElementValue(".billSubtotal");
    this.discount = this.getBillElementValue(`${chargesSelector} .discount`);
    this.deliveryCost = this.getBillElementValue(
      `${chargesSelector} .delivery`
    );
    this.taxCost = this.getBillElementValue(`${chargesSelector} .tax`);
    this.billTotal = this.getBillElementValue(".billTotalContainer .total");

    this.checkOutBtn = document.querySelector(
      `${containerSelector} .checkOutBtn`
    );
    this.continueShoppingBtn = document.querySelector(
      `${containerSelector} .continueShoppingBtn`
    );
    this.btnListener();
  }

  getBillElementValue(selector) {
    return this.billViewContainer.querySelector(`${selector} .value`);
  }

  updateBillSummary(billSummary) {
    const { subtotal, discount, delivery, tax } = billSummary;

    this.billSubtotal.innerHTML = this.formatValue(subtotal);
    this.discount.innerHTML = this.formatValue(discount);
    this.deliveryCost.innerHTML = this.formatValue(delivery);
    this.taxCost.innerHTML = this.formatValue(tax);

    const totalAmount = subtotal - discount + delivery + tax;
    this.billTotal.innerHTML = this.formatValue(totalAmount);
  }

  formatValue(value) {
    return `£${value.toFixed(2)}`;
  }

  btnListener() {
    this.checkOutBtn.addEventListener("click", () => {
      window.dispatchEvent(urgentCartUploadEvent);
      window.addEventListener("urgentCartUploadCompleted", async () => {
        try {
          const response = await fetch("/order/checkout");
          const result = await response.json();

          if (!response.ok) throw new Error(result.message);

          View.showNotification("Success", result.message, 5);
          setTimeout(() => {
            location.href = result.data;
          }, 5);
        } catch (err) {
          console.error(err);
          View.showNotification("Error", err.message, 5, "error");
        }
      });
    });

    this.continueShoppingBtn.addEventListener("click", () => {
      window.location.href = "/shop";
    });
  }
}

export default new BillView();

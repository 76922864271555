const PAGE_TITLE = document.querySelector("#pageTitle").dataset.pageTitle;

// Importing global modules
import * as navView from "./views/navView.js";
import * as cartController from "./controller/cartController.js";
import * as loginSignupController from "./controller/loginSignupController.js";

switch (PAGE_TITLE) {
  case "Home":
    import("./controller/homeController.js");
    import("./controller/shopController.js");
    break;

  case "Shop":
    import("./controller/shopController.js");
    break;

  case "Gallery":
    import("./controller/galleryController.js");
    break;

  case "Contact":
    import("./controller/contactController.js");
    break;

  case "Orders":
    import("./controller/accOrdersController.js");
    break;

  case "Profile":
    import("./controller/profileController.js");
    break;

  default:
    break;
}

if (module.hot) module.hot.accept();
